import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { Topbar } from './Topbar';
import { HeaderMenuWrapper } from './header-menu/HeaderMenuWrapper';
import { AnimateLoading } from '../../../_partials/controls';
import { BACKEND_URL, FRONTEND_URL } from '../../../../config';
import axios from 'axios';
import { useRouteMatch } from 'react-router-dom';

export function Header() {
  const uiService = useHtmlClassService();
  const { user, authToken, shop, shopSetting } = useSelector(state => state.auth);
  const isBookingDetailPage = useRouteMatch('/shop/:shopId/booking/:bookingId/detail');
  const isMGMPage = useRouteMatch('/shop/:shopId/mgm/detail');

  const hideAside = !user && (isBookingDetailPage || isMGMPage);

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display')
    };
  }, [uiService]);

  const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  const previewNav = previewTheme?.navColor;
  const isPreview = previewNav ? true : false;

  const initStyle = {
    background: '#1e1e2d'
  };

  const [background, setBackground] = useState(initStyle);

  useEffect(() => {
    const navColor = shopSetting?.backgroundColor?.navColor;
    const isShopSetting = navColor ? true : false;

    if (isPreview && previewNav) {
      setBackground({ background: previewNav });
    }
    if (isShopSetting && navColor) {
      setBackground({ background: navColor });
    }
    // else {
    //   setBackground(initStyle);
    // }
  }, [shopSetting]);

  return (
    <>
      {/*begin::Header*/}
      <div
        style={{ ...background, left: hideAside ? 0 : undefined }}
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {/* {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />} */}
          <div />
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
