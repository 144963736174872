import styled from 'styled-components';

export const ImageGrayContainer = styled.img`
  position: fixed;
  bottom: -60px;
  width: 100%;

  @media (max-width: 550px) {
    width: auto;
  }
`;

export const ImageGreenContainer = styled.img`
  position: fixed;
  bottom: 0px;
  width: 100%;

  @media (max-width: 550px) {
    width: auto;
  }
`;

export const Shopname = styled.label`
  font-weight: 600;
  font-size: 19px;
  color: white;
`