import React from 'react';
import { useField } from 'formik';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';

const getFieldCSSClasses = (touched, errors) => {
  const classes = ['form-control', 'form-control-solid'];
  if (touched && errors) {
    classes.push('is-invalid-select');
  }

  if (touched && !errors) {
    classes.push('is-valid-select');
  }

  return classes.join(' ');
};

export function Select({
  label,
  withFeedbackLabel = true,
  type = 'text',
  customFeedbackLabel,
  children,
  field,
  form: { touched, errors },
  className,
  ...props
}) {
  // const [field, meta] = useField(props);
  // const { touched, error } = meta;
  return (
    <>
      {label && <label>{label}</label>}
      <select
        className={getFieldCSSClasses(touched, errors) || 'form-control'}
        style={{ backgroundColor: '#fff', border: '1px solid #E5EAEE' }}
        {...field}
        {...props}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel erros={errors} touched={touched} label={label} customFeedbackLabel={customFeedbackLabel} />
      )}
    </>
  );
}
