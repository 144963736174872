/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, lazy } from 'react';
import liff from '@line/liff';
import { Redirect, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from '../_metronic/layout';
// import BasePage from './BasePage';
// import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import { LayoutSplashScreen } from '../_metronic/layout';
import AuthPage from './modules/Auth/pages/AuthPage';
import Logout from './modules/Auth/pages/Logout';
const BasePage = lazy(() => import('./BasePage'));
// const Logout = lazy(() => import('./modules/Auth/pages/Logout'));
// const AuthPage = lazy(() => import('./modules/Auth/pages/AuthPage'));

// import { useTheme } from '@material-ui/core';

// const initFacebookSdk = async () => {
//   window.fbAsyncInit = () => {
//     console.log('fb')
//     window.FB.init({
//       appId: process.env.REACT_APP_FACEBOOK_APP_ID,
//       status: true,
//       xfbml: false,
//       version: 'v10.0'
//     });
//   }

//   // load facebook sdk script
//   (function(d, s, id) {
//     var js,
//       fjs = d.getElementsByTagName(s)[0];
//     if (d.getElementById(id)) {
//       return;
//     }
//     js = d.createElement(s);
//     js.id = id;
//     js.src = 'https://connect.facebook.net/en_US/sdk.js';
//     fjs.parentNode.insertBefore(js, fjs);
//   })(document, 'script', 'facebook-jssdk');
// };

// const liff = window.liff;

const Routes = () => {
  const authToken = useSelector(state => state.auth.authToken);
  // const user = useSelector(({ auth }) => auth.user);
  // const dispatch = useDispatch();
  // const url = window.location.toString();
  // const params = new URLSearchParams(window.location.search);

  // useEffect(() => {
  //   window.alert(`URL: ${url}\nparams: ${params}
  //   `);
  // }, [url]);
  const history = useHistory();
  const matchShop = useRouteMatch('/shop/:shopId/');
  const redeemMatch = useRouteMatch('/shop/:shopId/redeem/:redeemId');
  const bookingMatch = useRouteMatch('/shop/:shopId/booking');
  const bookingDetailMatch = useRouteMatch('/shop/:shopId/booking/:bookingId/detail');
  const MGMDetailMatch = useRouteMatch('/shop/:shopId/mgm/detail');


  // const isLiff = params.get('liff');

  // useEffect(() => {
  //   const fetch = async () => {
  //     await initFacebookSdk();
  //   };
  //   fetch();
  // }, []);

  // useEffect(() => {
  //   const initLiff = async () => {
  //     await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
  //   };
  //   initLiff();
  // }, []);

  // useEffect(() => {
  //   const verifyAuthToken = async token => {
  //     try {
  //       const res = await getUserByToken(token);
  //     } catch (err) {
  //       console.log(err);
  //       const userType = user ? user.type : 'user';
  //       dispatch({ type: auth.actionTypes.Logout });
  //       if (userType === 'user' && matchShop) {
  //         history.push(`/shop/${matchShop.params.shopId}/login`);
  //       } else if (userType === 'admin') {
  //         history.push('/admin/login');
  //       } else {
  //         history.push('/error');
  //       }
  //     }
  //   };

  //   if (authToken) {
  //     verifyAuthToken(authToken);
  //   }
  // }, [authToken, dispatch, history, matchShop, user]);

  if (window.location.href.includes('liff.state')) return <LayoutSplashScreen />;

  if (!authToken) {
    if (redeemMatch && redeemMatch.params.shopId && redeemMatch.params.redeemId) {
      history.replace(`/shop/${matchShop.params.shopId}/login?redeem=${redeemMatch.params.redeemId}`);
    }
  }

  return (
    <Switch>
      <Route path="/error" component={ErrorsPage} />
      {!authToken && (bookingMatch || bookingDetailMatch|| MGMDetailMatch) && (
        <Layout>
          <Route>
            <AuthPage />
          </Route>
        </Layout>
      )}
      {!authToken && (
        <Route>
          <AuthPage />
        </Route>
      )}
      <Route path="/logout" component={Logout} />
      {authToken && (
        <Layout>
          <BasePage />
        </Layout>
      )}
      <Redirect to="/error" />
    </Switch>
  );
};

export default Routes;
