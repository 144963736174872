/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
// import { checkIsActive } from '../../../../_helpers';
import moment from 'moment';
import menu from './menu';
import { styled } from '@mui/material';
import toggle from './../../../../../_metronic/_assets/js/layout/base/aside.js';
import { getUserByToken } from '../../../../../app/modules/Auth/_redux/authCrud';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import { fetchBookingList } from '../../../../../app/modules/ECommerce/_redux/booking/actions';
import { getBookingSetting } from '../../../../../app/modules/ECommerce/_redux/booking/crud';
import { fetchPendingOrderList } from '../../../../../app/modules/ECommerce/_redux/order/productsActions';
import { BACKEND_URL, FRONTEND_URL } from '../../../../../config';
import { fadeColor, hexToRgb } from '../../../../../app/pages/shop-setting/suggest-color';
import axios from 'axios';
import {
  useIsCouponActive,
  useIsPackageActive,
  useIsPromotionActive,
  useIsSalesQrActive,
  useIsShoppingCartActive
} from '../../../../../app/hooks/useFeatureFlag';

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const { user, shop, authToken, shopSetting, shopPointandWalletSetting, customerMenuSetting } = useSelector(
    state => state.auth
  );

  const pointText =
    shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.point' });
  const translate = text => {
    return customerMenuSetting?.menu.filter(elem => elem.name === text)?.[0]?.[intl.locale.toUpperCase()];
  };

  const changeMenu = [
    'MENU_REDEEM_HISTORY',
    'menu.expire.points',
    'menu.add.point.by.customer',
    'menu.add.point',
    'MENU_CREATE_POINT_QRCODE',
    //temp
    'SHOP.PACKAGE.CREATE',
    'menu.booking.management',
    'MENU_CREATE_PROMOTION',
    'MENU_CREATE_COUPONS',
    'MENU.CONTACT_US',
    'menu.booking',
    'MENU.SHOW_DEPOSIT'
  ];

  const changeSubMenu = [
    'MENU_REDEEM_HISTORY',
    'menu.expire.points',
    //temp
    'menu.conversion.rate',
    'menu.coupon.history',
    'menu.booking.history',
    'menu.booking.management',
    'menu.booking.setting',
    'menu.invite.customer.booking',
    'MENU.CONTACT_US',
    'MENU_USER_MANAGE',
    'menu.order.history.new'
  ];
  const packageText = translate('menu.user.packages') || intl.formatMessage({ id: 'menu.user.packages' });
  const couponText = translate('menu.user.coupons') || intl.formatMessage({ id: 'menu.user.coupons' });
  const promotionText = translate('menu.reward') || intl.formatMessage({ id: 'menu.reward' });
  const depositText = translate('menu.deposit') || intl.formatMessage({ id: 'menu.deposit' });
  const contactText = translate('menu.contact.us') || intl.formatMessage({ id: 'menu.contact.us' });
  const bookingText = translate('menu.booking.new') || intl.formatMessage({ id: 'menu.booking.old' });
  const orderHistoryText = translate('menu.order.history') || intl.formatMessage({ id: 'menu.order.history' });

  const [bookingSetting, setBookingSetting] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const isBookingShop = shop?.isBooking || false;
  const isGrantonShop = shop?.isGranton || false;
  const isShortcutShop = shop?.isShortcut || false;
  const couponEnabled = useIsCouponActive();
  const packageEnabled = useIsPackageActive();
  const promotionEnabled = useIsPromotionActive();
  const isContactActive = shop?.contactEnabled || false;
  const isSalesQrActive = useIsSalesQrActive();

  const isGrantonActiveShop = shop?.activeShopGranton || false;
  const isGrantonActiveCustomer = shop?.activeCustomerGranton || false;

  const shoppingCartEnabled = useIsShoppingCartActive();
  const isCustomer = user?.type === 'customer';
  const bookingData = useSelector(state => state.booking.items);
  const waitConfirmOrderAmount = useSelector(state => state.order.totalWaitConfirmCount);

  const initialMenuListToggleStatus =
    user && menu(user?.seqId)[user?.type].reduce((acc, elem) => ({ ...acc, [elem.id]: false, 'menu.home': true }), {});
  const [menuListStatus, setMenuListStatus] = useState(initialMenuListToggleStatus || {});
  const waitConfirmBookingAmount = bookingData.filter(item => item.status === 'wc' && moment(item.date) > moment())
    .length;

  const getMenuItemActive = url => {
    return location.pathname === url ? ' menu-item-active menu-item-open ' : '';
  };

  useEffect(() => {
    if (user?.seqId == null) return;
    getBookingSetting(user.seqId).then(res => setBookingSetting(res.data.queryResult || null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.seqId]);

  const backgroundInit = {
    background: '#1e1e2d'
  };

  const focusInit = {
    background: '#7ab8b6'
  };

  const fontColorInit = {
    color: '#B5B5C3'
  };

  const hoverFontColorInit = {
    color: '#ffffff'
  };

  const [background, setBackground] = useState(backgroundInit);
  const [fadeBackground, setFadeBackground] = useState({ background: '#000' });
  const [focusColor, setFocusColor] = useState(focusInit);
  const [fontColor, setFontColor] = useState(fontColorInit);
  const [hoverFontColor, setHoverFontColor] = useState(hoverFontColorInit);

  const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  const previewNav = previewTheme?.navColor;
  const previewIcon = previewTheme?.iconColor;
  const previewFont = previewTheme?.fontColor;
  const isPreview = previewNav || previewIcon || previewFont ? true : false;

  useEffect(() => {
    const navColor = shopSetting?.backgroundColor?.navColor;
    const iconColor = shopSetting?.backgroundColor?.iconColor;
    const fontColor = shopSetting?.backgroundColor?.fontColor;
    const isShopSetting = navColor || iconColor || fontColor ? true : false;

    if (isPreview && previewNav) {
      let fade = fadeColor(previewNav, 0.5);
      setBackground({ background: previewNav });
      setFadeBackground({ background: fade });
    }
    if (isPreview && previewIcon) {
      setFocusColor({ background: previewIcon });
    }
    if (isPreview && previewFont) {
      let hover = fadeColor(previewFont, -0.2);
      setHoverFontColor({ color: hover });
      setFontColor({ color: previewFont });
    }
    if (isShopSetting && fontColor) {
      let hover = fadeColor(fontColor, -0.7);
      setHoverFontColor({ color: hover });
      setFontColor({ color: fontColor });
    }
    if (isShopSetting && iconColor) {
      setFocusColor({ background: iconColor });
    }
    if (isShopSetting && navColor) {
      let fade = fadeColor(navColor, 0.5);
      setBackground({ background: navColor });
      setFadeBackground({ background: fade });
    }
  }, [shopSetting]);

  const handelMounseEnter = (e, type, focus) => {
    const isFocus = focus && location.pathname === focus?.route;
    if (isFocus) {
      return;
    }
    if (type === 'menu') {
      let fade = fadeColor(background?.background, -0.2);
      e.currentTarget.style.background = fade;
    }
    if (type === 'submenu') {
      e.currentTarget.style.background = background?.background;
    }
    if (type === 'font') {
      e.currentTarget.style.color = hoverFontColor?.color;
    }
  };

  const handelMounseLeave = (e, type, focus) => {
    const isFocus = focus && location.pathname === focus?.route;
    if (isFocus) {
      return;
    }
    if (type === 'menu') {
      e.currentTarget.style.background = background?.background;
    }
    if (type === 'submenu') {
      e.currentTarget.style.background = fadeBackground?.background;
    }
    if (type === 'font') {
      e.currentTarget.style.color = fontColor?.color;
    }
  };
  const [hiddenMenuItems, setHiddenMenuItems] = useState([]);
  const [hiddenSubMenuItems, setHiddenSubMenuItems] = useState([]);

  const filterMenu = item => {
    if (user.type === 'shop-custom' && user?.customRole && hiddenMenuItems.includes(item.id)) return false;

    if (!isBookingShop && item.id.includes('menu.booking')) {
      return false;
    }

    if (!bookingSetting?.isActive && isCustomer && item.id.includes('menu.booking')) {
      return false;
    }

    const unallowedShopGrantop = ['menu.granton.list', 'menu.granton.action', 'menu.granton.history'];
    if (!isGrantonShop && item.id.includes('menu.granton')) return false;
    if (!isGrantonActiveShop && unallowedShopGrantop.includes(item.id)) return false;

    const unallowedShoppingCart = ['menu.order.history', 'menu.shop.management'];
    if (!shoppingCartEnabled && unallowedShoppingCart.includes(item.id)) return false;
    if (!couponEnabled && item.id === 'MENU_CREATE_COUPONS') return false;
    if (!packageEnabled && item.id === 'SHOP.PACKAGE.CREATE') return false;
    if (!promotionEnabled && item.id === 'MENU_CREATE_PROMOTION') return false;
    if (!isContactActive && item.id.includes('contact.management')) return false;
    if (!isSalesQrActive && item.id === 'menu.sales.management') return false;

    return true;
  };

  const filterSubMenu = sub => {
    if (!shop) return false;
    if (user?.type === 'shop-custom' && user?.customRole && hiddenSubMenuItems.includes(sub.id)) return false;
    if (!shop.isBooking && sub.id === 'menu.booking.history') return false;
    if (!shop.enableShoppingCart && (sub.id === 'menu.order.history' || sub.id === 'menu.order.history.new'))
      return false;

    if (!shop.enableLineNotification && !shop.enableWhatsapp && sub.id === 'menu.broadcast.manage') return false;
    // if (!shop?.enableLineNotification && sub.id === 'menu.broadcast.manage') return false;

    if (
      !shop.enableLineNotification &&
      !shop.enableWhatsapp &&
      ['MENU_FILTER_MANAGEMENT', 'menu.broadcast.manage'].includes(sub.id)
    )
      return false;

    if (
      (!shop.enableLineNotification || (!shop.enableShoppingCart && !shop.isBooking)) &&
      sub.id.includes('menu.lineOA.setting')
    )
      return false;

    if (
      !shop.enableMGM &&
      ['MENU_MEMBER_GET_MEMBER_MANAGEMENT', 'MENU_MEMBER_GET_MEMBER_HISTORY', 'MENU_MEMBER_GET_MEMBER_REPORT'].includes(
        sub.id
      )
    )
      return false;

    if (!isShortcutShop && sub.id === 'menu.shortcut.setting') return false;
    if (!shop.appServiceSMSEnabled && sub.id === 'menu.sms.setting') return false;
    return true;
  };

  useEffect(() => {
    if (user?.customRole && shop) {
      const { menu, submenu } = getRoleMenu(user?.customRole);
      setHiddenMenuItems(prev => [...prev, ...menu]);
      setHiddenSubMenuItems(prev => [...prev, ...submenu]);
    }
  }, [user, shop]);

  const menuBackground = menu => {
    if (location.pathname === menu.route) return focusColor?.background;
    if (menu.isSubMenu) return fadeBackground?.background;
    return background?.background;
  };

  return (
    <ul className={`menu-nav`}>
      {user &&
        menu(user.seqId)
          [user.type].filter(filterMenu)
          .map((item, index) => {
            return (
              <Fragment key={item.id}>
                <li
                  id="kt_aside_toggle"
                  onClick={() => {
                    if (item.submenu) {
                      // setOpenShopManagement(!openShopManagement);
                      setMenuListStatus(prev => ({ ...prev, [item.id]: !prev[item.id] }));
                    } else {
                      toggle.getOffcanvas().hide();
                      history.push(item.route || '/');
                    }
                  }}
                  className={`menu-item ${getMenuItemActive(item.route)}`}
                  style={item.isSubMenu && { backgroundColor: '#000' }}
                  aria-haspopup="true"
                  role="menuitem"
                >
                  <div
                    className="menu-link"
                    // style={{ padding: item.isSubMenu ? '9px 0px 9px 40px' : '9px 0px 9px 25px' }}
                    style={{
                      padding: item.isSubMenu ? '9px 0px 9px 40px' : '9px 0px 9px 25px',
                      background: menuBackground(item)
                      // location.pathname === item.route
                      //   ? focusColor?.background
                      //   : item.isSubMenu
                      //   ? fadeBackground?.background
                      //   : background?.background
                    }}
                    onMouseEnter={e => handelMounseEnter(e, 'menu', item)}
                    onMouseLeave={e => handelMounseLeave(e, 'menu', item)}
                  >
                    <span
                      className="menu-text font-weight-bold"
                      onMouseEnter={e => handelMounseEnter(e, 'font', item)}
                      onMouseLeave={e => handelMounseLeave(e, 'font', item)}
                      style={{
                        color: location.pathname === item.route ? hoverFontColor?.color : fontColor?.color
                      }}
                    >
                      {changeMenu.includes(item.id) ? (
                        intl.formatMessage(
                          { id: item.id },
                          {
                            package: packageText,
                            booking: bookingText,
                            coupon: couponText,
                            promotion: promotionText,
                            contact: contactText,
                            deposit: depositText,
                            point: pointText
                          }
                        )
                      ) : (
                        //  tempprary customer menu
                        <FormattedMessage id={item.id} />
                      )}
                    </span>
                    {item.route === `booking-management` && waitConfirmBookingAmount !== 0 && !isCustomer && (
                      <NotificationIcon>{waitConfirmBookingAmount}</NotificationIcon>
                    )}
                  </div>
                </li>
                {// openShopManagement &&
                menuListStatus[item.id] &&
                  item.submenu &&
                  item.submenu.filter(filterSubMenu).map((sub, idx) => {
                    return (
                      <li
                        key={`${sub.id}`}
                        id="kt_aside_toggle"
                        onClick={e => {
                          toggle.getOffcanvas().hide();
                        }}
                        className={`menu-item ${getMenuItemActive(sub.route)}`}
                        aria-haspopup="true"
                        role="menuitem"
                      >
                        <NavLink
                          className="menu-link"
                          to={sub.route || '/'}
                          // style={sub.isSubMenu && { padding: '9px 0px 9px 40px' }}
                          style={{
                            padding: sub.isSubMenu ? '9px 0px 9px 40px' : '9px 0px 9px 25px',
                            background: menuBackground(sub)
                            // location.pathname === sub.route
                            //   ? focusColor?.background
                            //   : sub.isSubMenu
                            //   ? fadeBackground?.background
                            //   : background?.background
                          }}
                          onMouseEnter={e => handelMounseEnter(e, 'submenu', sub)}
                          onMouseLeave={e => handelMounseLeave(e, 'submenu', sub)}
                        >
                          <span
                            className="menu-text font-weight-bold"
                            style={{
                              color: location.pathname === sub.route ? hoverFontColor?.color : fontColor?.color
                            }}
                            onMouseEnter={e => handelMounseEnter(e, 'font', sub)}
                            onMouseLeave={e => handelMounseLeave(e, 'font', sub)}
                          >
                            {changeSubMenu.includes(sub.id) ? (
                              intl.formatMessage(
                                { id: sub.id },
                                {
                                  point: pointText,
                                  coupon: couponText,
                                  deposit: depositText,
                                  contact: contactText,
                                  booking: bookingText,
                                  order: orderHistoryText
                                }
                              )
                            ) : (
                              <FormattedMessage id={sub.id} />
                            )}
                          </span>
                          {sub.route === `/shop/${user.seqId}/booking-management` &&
                            waitConfirmBookingAmount !== 0 &&
                            !isCustomer && <NotificationIcon>{waitConfirmBookingAmount}</NotificationIcon>}
                          {sub.route === `/shop/${user.seqId}/admin/orderManagement` &&
                            waitConfirmOrderAmount !== 0 &&
                            !isCustomer && <NotificationIcon>{waitConfirmOrderAmount}</NotificationIcon>}
                        </NavLink>
                      </li>
                    );
                  })}
              </Fragment>
            );
          })}
    </ul>
  );
}

const NotificationIcon = styled('div')(() => ({
  backgroundColor: 'red',
  padding: 4,
  width: 20,
  height: 20,
  borderRadius: 50,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  color: 'white',
  margin: '4px 10px 0 0'
}));

const initState = {
  dashboard: false,
  members: { invite: false, group: false, user: false },
  advertisement: { advertisement: false, broadcast: false },
  shopManage: {
    category: false,
    product: false,
    inventory: false,
    payment: false,
    shipment: false,
    VATservice: false,
    order: false
  },
  specialpackage: false,
  booking: { manage: false, setting: false, invite: false },
  // adminManage: false,
  reward: false,
  coupon: false,
  qrStaff: false,
  qrPoint: false,
  addpointCustomer: false,
  addpoint: false,
  pointHistory: false,
  report: false,
  setting: { shop: false, conversion: false, theme: false, shortcut: false, line: false, contact: false }
};

const getRoleMenu = customRole => {
  let menu = [];
  let submenu = [];

  menu.push('MENU_ADMIN_MANAGE');
  Object.entries(customRole).forEach(([key, val]) => {
    if (key === 'dashboard' && !val) menu.push('menu.dashboard');
    if (key === 'specialpackage' && !val) menu.push('SHOP.PACKAGE.CREATE');
    // if (key === 'adminManage' && !val) setHiddenMenuItems(prev => [...prev, 'MENU_ADMIN_MANAGE']);
    if (key === 'reward' && !val) menu.push('MENU_CREATE_PROMOTION');
    if (key === 'coupon' && !val) menu.push('MENU_CREATE_COUPONS');
    if (key === 'qrStaff' && !val) menu.push('menu.sales.management');
    if (key === 'qrPoint' && !val) menu.push('MENU_CREATE_POINT_QRCODE');
    if (key === 'addpointCustomer' && !val) menu.push('menu.add.point.by.customer');
    if (key === 'addpoint' && !val) menu.push('menu.add.point');
    if (key === 'pointHistory' && !val) menu.push('MENU_REDEEM_HISTORY');
    if (key === 'report' && !val) menu.push('MENU_REPORT');
    if (key === 'members' && !val?.invite && !val?.group && !val?.user) menu.push('MENU_MEMBER');
    if (key === 'advertisement' && !val?.advertisement && !val?.broadcast) menu.push('menu.advertisement');

    if (
      key === 'shopManage' &&
      !val?.category &&
      !val?.product &&
      !val?.inventory &&
      !val?.payment &&
      !val?.shipment &&
      !val?.VATservice &&
      !val?.order
    )
      menu.push('menu.shop.management');
    if (key === 'booking' && !val?.manage && !val?.setting && !val?.invite) menu.push('menu.booking.management');

    if (
      key === 'setting' &&
      !val?.shop &&
      !val?.conversion &&
      !val?.theme &&
      !val?.shortcut &&
      !val?.line &&
      !val?.contact
    )
      menu.push('MENU_SETTING');

    if (key === 'contactManagement' && !val.contactAssignable) menu.push('contact.management');

    if (typeof val === 'object')
      Object.entries(val).forEach(([subkey, val]) => {
        if (key === 'members' && subkey === 'invite' && !val) submenu.push('MENU_INVITE_FRIEND');
        if (key === 'members' && subkey === 'group' && !val) submenu.push('MENU_MANAGE_MEMBER_GROUP');
        if (key === 'members' && subkey === 'user' && !val) submenu.push('MENU_USER_MANAGE');
        if (key === 'contactManagement' && subkey === 'assignContact' && !val) {
          submenu.push('contact.management');
        }
        if (key === 'advertisement' && subkey === 'advertisement' && !val) submenu.push('menu.advertisement.manage');
        if (key === 'advertisement' && subkey === 'broadcast' && !val) submenu.push('menu.broadcast.manage');
        if (key === 'shopManage' && subkey === 'category' && !val) submenu.push('menu.category');
        if (key === 'shopManage' && subkey === 'product' && !val) submenu.push('menu.product.list');
        if (key === 'shopManage' && subkey === 'inventory' && !val) submenu.push('menu.inventory');
        if (key === 'shopManage' && subkey === 'payment' && !val) submenu.push('menu.payment.management');
        if (key === 'shopManage' && subkey === 'shipment' && !val) submenu.push('menu.shipment.management');
        if (key === 'shopManage' && subkey === 'VATservice' && !val) submenu.push('menu.shopping.cart.setting');
        if (key === 'shopManage' && subkey === 'order' && !val) submenu.push('menu.admin.order.history');
        if (key === 'booking' && subkey === 'manage' && !val) submenu.push('menu.booking.management');
        if (key === 'booking' && subkey === 'invite' && !val) submenu.push('menu.invite.customer.booking');
        if (key === 'booking' && subkey === 'setting' && !val) submenu.push('menu.booking.setting');
        if (key === 'setting' && subkey === 'shop' && !val) submenu.push('MENU_SHOP_SETTING');
        if (key === 'setting' && subkey === 'conversion' && !val) submenu.push('menu.conversion.rate');
        if (key === 'setting' && subkey === 'theme' && !val) submenu.push('menu.theme.setting');
        if (key === 'setting' && subkey === 'shortcut' && !val) submenu.push('menu.shortcut.setting');
        if (key === 'setting' && subkey === 'line' && !val) submenu.push('menu.lineOA.setting');
        if (key === 'setting' && subkey === 'contact' && !val) submenu.push('MENU.CONTACT_US');
      });
  });
  return { menu, submenu };
};
