import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Link, useLocation } from 'react-router-dom';
import objectPath from 'object-path';
import styled from 'styled-components';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import FreePersonIcon from '../../../../assets/free_person.png';
import { LanguageSelectorDropdown } from '../extras/dropdowns/LanguageSelectorDropdown';
import { AnnouncementsDropdown } from '../extras/dropdowns/AnnouncementsDropdown';
import { useSelector } from 'react-redux';
import { Shopname } from '../style';
import { BACKEND_URL, FRONTEND_URL } from '../../../../config';

import axios from 'axios';
const PersonIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 6px;
`;

// TODO: Icon header
export function HeaderMobile() {
  const history = useHistory();

  const location = useLocation();
  const uiService = useHtmlClassService();
  const { user, authToken, shop, shopSetting } = useSelector(state => state.auth);

  const isBookingPage = location.pathname.includes('/booking');
  const isMGMPage = location.pathname.includes('mgm');

  const isHideUserDetail = isBookingPage && !user;
  const isHideMGMDetail = isMGMPage && !user;
  const seqId = shop?.seqId;

  const showProfilePic = user && (user.facebookProfile || (user.lineProfile && user.lineProfile.pictureUrl));
  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMenuSelfDisplay: objectPath.get(uiService.config, 'header.menu.self.display') === true,
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile')
    };
  }, [uiService]);
  const isOwnerOrAdmin = user && (user.type === 'shop-owner' || user.type === 'shop-admin');

  const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  const previewNav = previewTheme?.navColor;
  const isPreview = previewNav ? true : false;

  const initStyle = {
    background: ''
  };
  const [background, setBackground] = useState(initStyle);

  useEffect(() => {
    const navColor = shopSetting?.backgroundColor?.navColor;
    const isShopSetting = navColor ? true : false;

    if (isPreview && previewNav) {
      setBackground({ background: previewNav });
    }
    if (isShopSetting && navColor) {
      setBackground({ background: navColor });
    }
    // else {
    //   setBackground(initStyle);
    // }
  }, [shopSetting]);

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        style={{ ...background }}
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        <div className="d-flex align-items-center">
          <LanguageSelectorDropdown />
          {isOwnerOrAdmin && <AnnouncementsDropdown />}
          {isPreview ? (
            <div
              style={{ display: 'flex', flexDirection: 'row', color: 'red', margin: '0px 5px' }}
              onClick={() => {
                window.localStorage.removeItem('THEME_PREVIEW');
                history.push(`/shop/${seqId}/setting`);
                window.location.reload(false);
              }}
            >
              {/* <FontAwesomeIcon icon={faExclamation} size="2x" s/> */}
              <h1>!!! preview theme</h1>
            </div>
          ) : null}
        </div>
        {/*begin::Logo*/}
        <Link to={`/shop/${seqId}/home`}>
          <Shopname>{shop && shop.name}</Shopname>
        </Link>
        {/*end::Logo*/}

        {/*begin::Toolbar*/}

        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              {(!isHideUserDetail || !isHideMGMDetail) && (
                <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                  <span />
                </button>
              )}
              {/*end::Aside Mobile Toggle*/}
            </>
          )}

          {/* {layoutProps.headerMenuSelfDisplay && (
                <>
                  
                  <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                    <span/>
                  </button>
                  
                </>
            )} */}

          {/*begin::Topbar Mobile Toggle*/}
          {(!isHideUserDetail || !isHideMGMDetail) && (
            <button
              className="btn btn-hover-text-primary p-0 ml-2"
              onClick={() => {
                const toggle = document.getElementById('kt_quick_user_toggle');
                if (toggle) {
                  toggle.click();
                }
              }}
            >
              <span className="svg-icon svg-icon-xl">
                <PersonIcon src={showProfilePic || FreePersonIcon} />
                {/* <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} /> */}
              </span>
            </button>
          )}
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
