import React, { useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useSelector } from 'react-redux';
import { Brand } from '../brand/Brand';
import { AsideMenu } from './aside-menu/AsideMenu';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import useWindowSize from '../../../../app/pages/dashboard/chart/WindowSize';
import { BACKEND_URL } from '../../../../config';
// import './extraStyle.css';
import axios from 'axios';

export function Aside() {
  const uiService = useHtmlClassService();
  const { shop, shopSetting } = useSelector(state => state.auth);
  const size = useWindowSize();
  const layoutProps = useMemo(() => {
    return {
      disableScroll: objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
      asideClassesFromConfig: uiService.getClasses('aside', true),
      disableAsideSelfDisplay: objectPath.get(uiService.config, 'aside.self.display') === false,
      headerLogo: uiService.getLogo()
    };
  }, [uiService]);

  const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  const previewNav = previewTheme?.navColor;
  const isPreview = previewNav ? true : false;
  

  const initStyle = {
    background: ''
  };

  const [background, setBackground] = useState(initStyle);

  useEffect(() => {
    const navColor = shopSetting?.backgroundColor?.navColor;
    const isShopSetting = navColor ? true : false;

    if (isPreview && previewNav) {
      setBackground({ background: previewNav });
    }
    if (isShopSetting && navColor) {
      setBackground({ background: navColor });
    }
    // else {
    //   setBackground(initStyle);
    // }
  }, [shopSetting]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto `}
        style={{ overflow: 'auto', ...background }}
      >
        <Brand />
        <div className="d-flex flex-wrap mb-5">
          {shop && shop.logo && size.width < 550 && (
            <img
              alt="Logo"
              className="img-fluid mx-auto mb-5"
              style={{ maxHeight: 60 }}
              src={shop.logo && `${BACKEND_URL}/${shop.logo.data}`}
            />
          )}
        </div>
        {/* begin::Aside Menu */}
        <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
          {layoutProps.disableAsideSelfDisplay && (
            <>
              {/* begin::Header Logo */}
              <div className="header-logo">
                <Link to="">
                  <img alt="logo" src={layoutProps.headerLogo} />
                </Link>
              </div>
              {/* end::Header Logo */}
            </>
          )}
          <AsideMenu disableScroll={layoutProps.disableScroll} />
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}
