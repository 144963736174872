import { createSlice } from '@reduxjs/toolkit';

const INITIAL_PACKAGE_FOR_EDIT_STATE = {
  _id: '',
  name: '',
  description: '',
  limit: null,
  limitOfPurchasePerUserPerDay: null,
  limitOfPurchasePerDay: null,
  numberOfUses: null,
  start: null,
  end: null,
  isActive: true,
  image: [],
  originalPrice: 0,
  speicalPrice: 0,
  shop: null,
  resizeImage: ''
};

const INITIAL_CUSTOMER_SINGLE_PACKAGE_STATE = {
  ...INITIAL_PACKAGE_FOR_EDIT_STATE,
  uses: [],
  currentPackageStart: null,
  currentPackageEnd: null,
  currentPackageUsesLimit: null,
  grantedAt: new Date().toString(),
  purchasedAt: new Date().toString(),
  isGranted: false,
  userPackageId: ''
};

const initialPackagesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  packageForEdit: INITIAL_PACKAGE_FOR_EDIT_STATE,
  customerSinglePackage: INITIAL_CUSTOMER_SINGLE_PACKAGE_STATE,
  lastError: null,
  customerPackages: [],
  customer: {
    user: {
      firstName: '',
      lastName: ''
    }
  }
};

export const callTypes = {
  list: 'list',
  action: 'action'
};

export const packagesSlice = createSlice({
  name: 'packages',
  initialState: initialPackagesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      console.log(action.payload.error);
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductById
    packageFetched: (state, action) => {
      state.actionsLoading = false;
      state.packageForEdit = action.payload.packageForEdit;
      state.error = null;
    },
    // findProducts
    packagesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = entities.length;
    },
    // createProduct
    productCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.product);
    },
    customerFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.customerPackages = action.payload.packages;
      state.customer = action.payload.customer;
    },
    customerPackageDeleted: (state, action) => {
      const deleteId = action.payload.id;
      state.listLoading = false;
      state.error = null;
      state.customerPackages = state.customerPackages.filter(userPack => userPack.userPackageId !== deleteId);
    },
    customerSinglePackageFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.customerSinglePackage = action.payload.singlePackage;
    }
  }
});
