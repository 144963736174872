import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import { customersSlice } from '../app/modules/ECommerce/_redux/customers/customersSlice';
import { customersManageSlice } from '../app/modules/ECommerce/_redux/customersManage/customersSlice';
import { customerProductsSlice } from '../app/modules/ECommerce/_redux/customerProduct/customersSlice';
import { shopProductsSlice } from '../app/modules/ECommerce/_redux/shopProducts/productsSlice';
import { productsSlice } from '../app/modules/ECommerce/_redux/products/productsSlice';
// import { packagesSlice } from '../app/modules/ECommerce/_redux/oldPackages/customersSlice';
import { remarksSlice } from '../app/modules/ECommerce/_redux/remarks/remarksSlice';
import { specificationsSlice } from '../app/modules/ECommerce/_redux/specifications/specificationsSlice';
import { topupSlice } from '../app/modules/ECommerce/_redux/topup/customersSlice';
import { qrCodeSlice } from '../app/modules/ECommerce/_redux/qrCode/customersSlice';
import { ShoppingCartSlice } from '../app/modules/ECommerce/_redux/shoppingCart/reducer';
import { orderSlice } from '../app/modules/ECommerce/_redux/order/productsSlice';
import { groupSlice } from '../app/modules/ECommerce/_redux/groupManage/groupSlice';
import { packagesSlice } from '../app/modules/ECommerce/_redux/packages/packagesSlice';
import { BookingSlice } from '../app/modules/ECommerce/_redux/booking/slice';
import { announcementsSlice } from '../app/modules/Auth/_redux/announcement/announcementsSlice';
import { couponSlice } from '../app/modules/ECommerce/_redux/coupons/couponSlice';
import { shipmentSlice } from '../app/modules/ECommerce/_redux/shipment/shipmentSlice';
import { weightSlice } from '../app/modules/ECommerce/_redux/shipment/weight/weightSlice';
import { addressSlice } from '../app/modules/ECommerce/_redux/address/addressSlice';
import { smsSettingSlice } from '../app/modules/ECommerce/_redux/smsSetting/smsSettingSlice';
import { UserRoleSlice } from '../app/modules/ECommerce/_redux/roleManage/slice';
import { ItemsSlice } from '../app/modules/ECommerce/_redux/items/itemsSlice';
import { AdvertisementSlice } from '../app/modules/ECommerce/_redux/advertisement/advertisementSlice';
import { BroadcastSlice } from '../app/modules/ECommerce/_redux/broadcast/broadcastSlice';
import { MemberGetMemberSlice } from '../app/modules/ECommerce/_redux/mgm/mgmSlice';
import { boardSlice } from '../app/modules/ECommerce/_redux/contactBoard/contactBoardSlice';
import { ConditionSlice } from '../app/modules/ECommerce/_redux/condition/condSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  customersManage: customersManageSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  customerProducts: customerProductsSlice.reducer,
  packages: packagesSlice.reducer,
  topup: topupSlice.reducer,
  qrCode: qrCodeSlice.reducer,
  shopProducts: shopProductsSlice.reducer,
  shoppingCart: ShoppingCartSlice.reducer,
  order: orderSlice.reducer,
  groups: groupSlice.reducer,
  booking: BookingSlice.reducer,
  announcements: announcementsSlice.reducer,
  coupons: couponSlice.reducer,
  shipment: shipmentSlice.reducer,
  weight: weightSlice.reducer,
  address: addressSlice.reducer,
  user: UserRoleSlice.reducer,
  advertisement: AdvertisementSlice.reducer,
  itemsList: ItemsSlice.reducer,
  broadcast: BroadcastSlice.reducer,
  membergetmember: MemberGetMemberSlice.reducer,
  smsSetting: smsSettingSlice.reducer,
  board: boardSlice.reducer,
  condition: ConditionSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
