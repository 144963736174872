import React, { useEffect } from 'react';
import { defaultLang, setLanguage, useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ja';
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh';

import deMessages from './messages/de';
import enMessages from './messages/en';
import esMessages from './messages/es';
import frMessages from './messages/fr';
import jaMessages from './messages/ja';
import zhMessages from './messages/zh';
import thMessages from './messages/th';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BACKEND_URL } from '../../config';

const allMessages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  th: thMessages
};

export function I18nProvider({ children }) {
  const user = useSelector(state => state.auth.user);
  const locale = useLang();
  const messages = allMessages[locale];

  async function compareLanguages() {
    try {
      //if the langauge in database doesn't match, set the language in the local machine to the saved language
      if (user?.setting?.lang && user.setting.lang !== locale) {
        setLanguage(user.setting.lang);
      } else if (user && !user.setting) {
        //if shopUserSetting collection doesn't exist upsert it based on the local machine's language
        await axios.put(`${BACKEND_URL}/shopUser/setting/`, {
          lang: locale || defaultLang,
          shopUser: user.shopUserId
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    compareLanguages();
  }, [user]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
