import React, { useMemo } from 'react';
import Select from 'react-select';
import { AVAILABLE_COUNTRIES } from '../../../config';
import { CountryCodes } from '../../../data/countryDialCodes';
import { mapCountryDialCodeToCountryObject } from '../../utils/countryService';

const countryList = AVAILABLE_COUNTRIES.map(c => {
  const found = CountryCodes.find(cc => cc.iso === c);
  if (!found) return null;
  return {
    label: `${found.country} +${found.code}`,
    value: found.code
  };
}).filter(t => t);
const countryListIso = AVAILABLE_COUNTRIES.map(c => {
  const found = CountryCodes.find(cc => cc.iso === c);
  if (!found) return null;
  return {
    label: `${found.iso}`,
    value: found.code
  };
}).filter(t => t);

function CountryDialCodePicker({
  //countryObject format: {label: "country_a", value: "55"} onChange output: {label: "country_a", value: "55"}
  countryObject: countryObjectInput,
  setCountryObject,
  //Pick either countryObject or countryDialCode format
  //countryDialCode format: input "55" onChange output: "55"
  countryDialCode,
  setCountryDialCode,
  //
  intl,
  disabled,
  hideLabel,
  placeholder,
  showIso,
  selectorStyle = {},
  formWrapperStyle = {},
  labelStyle = {},
  labelClass = ''
}) {
  const countryObject = useMemo(() => {
    if (countryObjectInput) return countryObjectInput;
    return mapCountryDialCodeToCountryObject(countryDialCode);
  }, [countryObjectInput, countryDialCode]);

  return (
    <div style={{ marginLeft: '1rem', ...formWrapperStyle }}>
      {!hideLabel && (
        <label style={{ fontSize: '1.4rem', marginBottom: '0.8rem', ...labelStyle }} className={labelClass}>
          {intl.formatMessage({ id: 'user.country.placeholder' })}
        </label>
      )}
      <div>
        <Select
          isDisabled={disabled}
          styles={{
            control: styles => ({
              ...styles,
              ...selectorStyle,
              paddingBlock: '0.5rem'
            })
          }}
          placeholder={placeholder || 'Select country'}
          options={showIso ? countryListIso : countryList}
          value={countryObject}
          onChange={value => {
            if (setCountryObject) {
              setCountryObject(value.value);
            } else {
              setCountryDialCode(value?.value);
            }
          }}
        />
      </div>
    </div>
  );
}

export default CountryDialCodePicker;
