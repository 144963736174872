const FeatureFlags = {
  MY: {
    dialCode: '60',
    login: {
      line: false,
      mobile: true
    },
    registration: {
      requirePassword: true
    },
    adminManage: {
      maximumOwner: 1,
      maximumAdmin: 3
    },
    homepage: {
      logoImageSize: 'contain'
    },
    availableCountries: ['MY', 'SG', 'ID', 'PH', 'TH', 'IN'],
    rate: {
      share: {
        line: false,
        whatsapp: true
      }
    },
    mgm: {
      customButton: false
    },
    onlinePayment: {
      enable2c2p: true,
      enableDragonpay: false
    }
  },
  TH: {
    dialCode: '66',
    login: {
      line: true,
      mobile: false
    },
    registration: {
      requirePassword: false
    },
    adminManage: {
      maximumOwner: 10,
      maximumAdmin: 20
    },
    homepage: {
      logoImageSize: 'cover'
    },
    availableCountries: ['MY', 'PH', 'TH', 'IN', 'SG', 'ID'],
    rate: {
      share: {
        line: true,
        whatsapp: true
      }
    },
    mgm: {
      customButton: true
    },
    onlinePayment: {
      enable2c2p: true,
      enableDragonpay: false
    }
  },
  PH: {
    dialCode: '63',
    login: {
      line: false,
      mobile: true
    },
    registration: {
      requirePassword: true
    },
    adminManage: {
      maximumOwner: 1,
      maximumAdmin: 3
    },
    homepage: {
      logoImageSize: 'contain'
    },
    availableCountries: ['MY', 'PH', 'TH', 'IN', 'SG', 'ID'],
    rate: {
      share: {
        line: false,
        whatsapp: true
      }
    },
    mgm: {
      customButton: false
    },
    onlinePayment: {
      enable2c2p: false,
      enableDragonpay: true
    }
  }
};

export const currentFeatureFlags = FeatureFlags[process.env.REACT_APP_COUNTRY];

export default FeatureFlags;
